<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" md="8" lg="6" xl="4" v-if="obCollection">
        <v-treeview
          :items="tree"
          :open.sync="open"
          rounded
          dense
          open-all
          ref="categories-tree"
        >
          <template #prepend="{ item, open }">
            <component
              size="1.5em"
              :is="
                item.children.length
                  ? open
                    ? iconFolderOpen
                    : iconFolder
                  : iconCategory
              "
            />
          </template>

          <template #append="{ item }">
            <v-btn icon text color="green" @click="create(item)">
              <icon-add size="1.2em" />
            </v-btn>

            <v-btn icon text color="green" @click="edit(item)">
              <icon-pencil-outline size="1.2em"></icon-pencil-outline>
            </v-btn>

            <v-btn
              icon
              text
              color="red"
              @click="deleteItem(item.id)"
              :disabled="!!item.children.length"
            >
              <icon-trash size="1.2em"></icon-trash>
            </v-btn>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
    <categories-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CategoriesMixin from "@/modules/categories/mixins/CategoriesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CategoriesForm from "@/modules/categories/components/Form.vue";
import ViewName from "@/modules/categories/components/ViewName.vue";
import { DataTableHeader } from "vuetify";
import { Route } from "vue-router";
import { Category, CategoryData } from "@planetadeleste/vue-mc-shopaholic";
import { findDeep } from "deepdash-es/standalone";
import { EventBus } from "@/services/event-bus";
import { AppModule } from "@/store/app";
import Utils from "@/services/Utils";

@Component({
  components: {
    ActiveIcon,
    CategoriesForm,
    ViewName,
  },
})
export default class CategoriesTree extends Mixins(CategoriesMixin) {
  iCounter = 0;
  open: number[] = [];
  isList = true;

  async onRouteChanged(to: Route) {
    if (to.name) {
      this.obItem = new Category();

      if (["categories.update"].includes(to.name)) {
        if (to.params.id) {
          this.obItem.id = to.params.id;
          await this.obItem.fetch();
        }

        this.showForm();
        return;
      } else if (["categories.create"].includes(to.name)) {
        if (to.params.id) {
          this.obItem.parent_id = Number(to.params.id);
        }

        this.showForm();
        return;
      }
    }

    this.hideForm();
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "code", value: "code" },
      {
        text: "parent.category",
        value: "parent_id",
        sortable: false,
      },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.company.selected", this.index);
    this.addEvent(`${this.sModelName}.after.save`, this.onAfterSave);
    this.addEvent(`${this.sModelName}.after.delete`, this.onAfterDelete);
    this.addEvent("before.show.form", this.getCode);
  }

  onAfterSave() {
    this.index();
    this.onAfterDelete();
  }

  async onAfterDelete() {
    await AppModule.loadCategories();
  }

  async index() {
    if (!this.obCollection) {
      return;
    }

    this.bLocalLoading = true;
    this.obCollection.clear();

    await this.obCollection.tree();
    this.iCounter++;

    this.bLocalLoading = false;

    this.$_.delay(() => {
      EventBus.emit(`${this.sCollectionName}.after.index`, this.obCollection);
      this.openAll();
    }, 500);
  }

  async edit(obItem: CategoryData) {
    await this.$router.push({
      name: "categories.update",
      params: { id: this.$_.toString(obItem.id) },
    });
  }

  async create(obItem: CategoryData) {
    await this.$router.push({
      name: "categories.create",
      params: { id: this.$_.toString(obItem.id) },
    });
  }

  openAll(obCategory?: CategoryData): void {
    if (!obCategory) {
      // @ts-ignore
      this.$_.forEach(this.obCollection.getModelList(), this.openAll);
    } else if (obCategory.children.length) {
      if (!this.open.includes(obCategory.id)) {
        this.open.push(obCategory.id);
      }

      this.$_.forEach(obCategory.children, this.openAll);
    }
  }

  async deleteItem(iItemId: number) {
    const obDeepEntry = findDeep(
      this.obCollection.getModelList(),
      (obCategory) => {
        return obCategory && obCategory.id == iItemId;
      },
      { childrenPath: ["children"] }
    );

    if (!obDeepEntry) {
      return;
    }

    this.remove(new Category(obDeepEntry.value));
  }

  getCode(obItem: Category) {
    if (obItem.id || obItem.code) {
      return;
    }

    Utils.nextCategoryCode().then((response) => {
      this.obItem.set("code", response.data);
    });
  }

  get iconFolder() {
    return "icon-folder-outline";
  }

  get iconFolderOpen() {
    return "icon-folder-open-outline";
  }

  get iconCategory() {
    return "icon-document-outline";
  }

  get tree() {
    this.iCounter;

    return this.obCollection.size() ? this.obCollection.getModelList() : [];
  }
}
</script>
